/* For default button  */
.btn-default-black {
  background: #475160;
  border: 1px solid #475160;
  border-radius: 8px;
  padding: 14px 20px 14px 24px;
  gap: 7px;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  font-weight: 700;
}

.btn-default-black .bi-chevron-down {
  color: #ced5dd;
}

.show > .btn-default-black.dropdown-toggle {
  color: #ffffff;
  background-color: #475160;
  border-color: #475160;
}

/* For export button on page header */
.btn-export-white {
  background: #ffffff;
  border: 1px solid #475160;
  border-radius: 8px;
  padding: 14px 20px 14px 24px;
  gap: 7px;
  display: flex;
  letter-spacing: 0.02em;
  color: var(--neutral-0, #475160);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.btn-export-figma {
  background: #475160;
  border: 1px solid #475160;
  border-radius: 8px;
  padding: 14px 20px 14px 24px;
  gap: 7px;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #333e46;
  font-weight: 700;
}
.txt-add-new {
  color: #fff;
}

.btn-export-white .bi-chevron-down {
  color: #333e46;
}

.btn-export-white .bi bi-chevron-down {
  color: #333e46;
}

.btn-export-white:hover,
.btn-export-white:active,
.btn-export-white:focus {
  background: #e2e5e9;
  color: #333e46;
  border: 1px solid #e2e5e9;
  transition: all 0.2s;
}
.wrap-store-add-new:hover {
  opacity: 0.6;
  transition: opacity 0.2s;
}
/* For clear button on page filter */
.btn-clear-all {
  background: #ffffff;
  border: 1px solid #e55b5c;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  background: 0;
  padding: 0px 24px;
}
.btn-clear-all-activity-page {
  background: #ffffff !important;
  border: 1px solid #e55b5c !important;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  background: 0;
  padding: 10px 24px;
}
.btn-clear-all-activity-page:hover {
  /* background-color: #ffffff !important; */
  opacity: 0.4;
  transition: all 0.2s;
}

.btn-clear-all-activity-page span {
  color: #cf0029 !important;
}

.btn-clear-all span {
  color: #cf0029 !important;
}

.btn-clear-all:hover,
.btn-clear-all:active,
.btn-clear-all:focus,
.show > .btn-clear-all.dropdown-toggle {
  background: #ffffff;
  border: 1px solid #e55b5c;
  color: #cf0029;
}

.btn-export-black.dropdown-toggle::after,
.btn-default-black.dropdown-toggle::after {
  content: unset;
}

.page-title-cus {
  /* padding: 10px 0; */
  letter-spacing: -0.02em;
  font-weight: 700;
  font-size: 23px !important;
  line-height: 24px;
  color: #475160;
  display: flex;
  align-items: center;
  gap: 10px;
}

.page-title img {
  margin-right: 15px;
  height: 24px;
  filter: brightness(0.9);
  /* padding-bottom: 5px; */
}

.border-bottom-white {
  border-bottom: solid 1px #e2e5e9 !important;
}

.btn-filter {
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #92a3b0;
  border-radius: 12px;
  padding: 16px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff !important;
}

.btn-filter:hover {
  opacity: 0.4;
  transition: all 0.2s;
}

.field-set {
  border: 1px solid #f1f3f6;
  border-radius: 12px;
  position: relative;
  padding: 6px;
  width: 100%;
}
.field-set.disabled input {
  opacity: 0.6;
}

.head-field-set {
  width: auto;
  position: absolute;
  left: 15px;
  top: -8px;
  padding: 0px 5px;
  background: #ffffff;
  color: #475160;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  z-index: 0;
}
.head-field-set-lunch {
  width: auto;
  position: absolute;
  left: 15px;
  top: -8px;
  padding: 0px 5px;
  background: #f9fafb;
  color: #475160;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  /* z-index: 2; */
}

.select-filter-items-date {
  width: 51% !important;
  border-right: solid 1px #e0e5eb !important;
  line-height: 10px;
}
.input-bg-search {
  padding: 5px;
  padding-left: 25px;
  background: url("../../assets/images/icons/breadcrumb/ico-search-bg.svg")
    #f9fafb;
  background-size: 20px;
  background-position: 5px;
  background-repeat: no-repeat;
}
.select-filter-items,
.select-filter-items:focus {
  outline: none !important;
}

table tbody {
  background-color: #ffffff00;
}
table tbody tr:last-child td:last-child {
  border-radius: 0px 0px 12px 0px;
}
table tbody tr:last-child td:first-child {
  border-radius: 0px 0px 0px 12px;
}
.prefix-tag {
  background-color: unset;
}

.wrap-body-activity .collapse .wrap-border-radius {
  border: 1px solid #f1f3f6;
  border-radius: 12px;
  padding: 10px;
}
.select-width-full .Select__control.css-13cymwt-control,
.select-width-full
  .Select__control.Select__control--is-focused.css-t3ipsp-control {
  width: 100%;
}
.wrap-table-opacity-0 thead tr th {
  background: #ffffff00 !important;
}
.table-border-0 .table-bordered > :not(caption) > * {
  border-width: 0 !important;
}
.w-15 {
  width: 15% !important;
}
.w-25 {
  width: 25% !important;
}
.w-5 {
  width: 5% !important;
}
.w-8 {
  width: 8% !important;
}
.w-27 {
  width: 27% !important;
}
.status .active {
  padding: 6px 8px;
  background: #d0f0dc;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #475160;
}
.wrap-row-padding {
  padding: 0 40px;
}
.wrap-inner-row-padding {
  padding: 0 0 0 14px;
}
.wrap-header-menu {
  border-radius: 16px;
}
.wrap-header-menu-top {
  padding: 24px !important;
}
.wrap-header-menu-bottom {
  padding: 24px !important;
}
.wrap-filter-bottom {
  position: relative;
}
.border-sofg-raduis {
  margin: 10px;
  border: solid 1px #e2e5e9 !important;
  border-radius: 14px;
}
.badge.rounded-pill.bage-green {
  background: #bfebd0 !important;
  border: 1.84615px solid #ffffff;
  border-radius: 5.53846px !important;
  padding: 4.15385px 5.07692px 3.23077px 6px;
  color: #475160 !important;
  width: 18.46px;
  height: 18.46px;
}
.badge.rounded-pill.bage-default {
  border-radius: 5.53846px;
  /* background: #f1f3f6 !important; */
  background: #bfebd0 !important;
  border: 1.84615px solid #ffffff;
  border-radius: 5.53846px !important;
  padding: 4.15385px 5.07692px 3.23077px 6px;
  color: #475160 !important;
  width: 18.46px;
  height: 18.46px;
}
.carousel .carousel-inner .carousel-item {
  height: 500px;
}
.carousel .carousel-inner .carousel-item img {
  width: inherit !important;
  height: inherit;
  object-fit: contain;
}
.card-heder-radius.card {
  border-radius: 16px 16px 0px 0px !important;
}
.panel-backgroup-white {
  background: #ffffff;
  border-radius: 0px 0 16px 16px;
}
.panel-backgroup-white .nav-wrapper-item .nav-link.active {
  padding: 8px 8px 0 0;
}
.panel-backgroup-white .nav-wrapper-item .nav-link {
  padding: 11px 8px 0 0;
}
/* .swal2-popup.swal2-modal.swal2-show {
  height: 500px !important;
}
.swal2-popup.swal2-modal.swal2-show .swal2-image {
  height: inherit;
  width: inherit;
  object-fit: contain;
  margin-top: 0;
} */
.select__control--is-disabled.css-16xfy0z-control {
  background-color: hsl(0deg 0% 100%) !important;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  background-color: #ffffff00 !important;
}

::-webkit-scrollbar-thumb {
  background: #899099 !important;
  border-radius: 5px;
}

.overflow-table-radius {
  border-radius: 10px !important;
  overflow-x: auto;
  background-color: #ffffff00 !important;
}
.table-scroll-tap .overflow-table-radius {
  overflow-y: auto;
  max-height: 300px;
}
.table-scroll-tap .overflow-table-radius.scroll-items-table {
  overflow-y: auto;
  max-height: 600px;
}
.table-scroll-tap.table-srcoll-exm-2 .overflow-table-radius {
  overflow-y: auto;
  max-height: 580px;
}
.table-scroll-tap .overflow-table-radius::-webkit-scrollbar {
  width: 5px;
}
.table-scroll-tap .overflow-table-radius table {
  border-collapse: collapse;
  width: 100%;
}
.table-scroll-tap .overflow-table-radius table thead tr th {
  position: sticky;
  top: 0px;
  z-index: 98;
}
.table-scroll-tap .overflow-table-radius table thead tr th,
.table-scroll-tap .overflow-table-radius table thead tr td {
  padding: 8px 16px;
}
/* scroll table */
.overflow-table-radius::-webkit-scrollbar {
  width: 13px;
  height: 7px;
}

.ico-h-16 {
  height: 16px;
}
.ico-h-18 {
  height: 18px;
}
.wrap-input-ico-gmv img {
  object-fit: contain;
}
.wrap-product-name {
  width: 250px;
  text-wrap: wrap;
}
