#global-loader {
  position: fixed;
  z-index: 50000;
  /* background: white; */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  opacity: 0.6;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -90px 0 0 -90px;
}
.circle {
  position: absolute;
  border: 3px solid transparent;
  /* border-top-color: #99d3f2; */
  border-top-color: #333e46;
  border-radius: 50%;
  -webkit-animation: rotate linear infinite;
  animation: rotate linear infinite;
}
.circle.one {
  height: 50px;
  width: 50px;
  left: 50px;
  top: 70px;
  -webkit-animation-duration: 0.85s;
  animation-duration: 0.85s;
}
.circle.two {
  height: 75px;
  width: 75px;
  top: 58px;
  left: 38px;
  -webkit-animation-duration: 0.95s;
  animation-duration: 0.95s;
}
.circle.three {
  height: 100px;
  width: 100px;
  top: 45px;
  left: 25px;
  -webkit-animation-duration: 1.05s;
  animation-duration: 1.05s;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -90px 0 0 -90px;
}

@-webkit-keyframes rotate {
  from {
    transform: rotateZ(360deg);
  }
  to {
    transform: rotateZ(0deg);
  }
}
@keyframes rotate {
  from {
    transform: rotateZ(360deg);
  }
  to {
    transform: rotateZ(0deg);
  }
}
