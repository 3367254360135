.loading-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust the height based on your layout */
  }
  
  .loading-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #343e46;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  