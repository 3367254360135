.hover-disable-color {
  background-color: transparent !important;
  color: inherit !important;
  cursor: default !important;
}
fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
}
.side-menu__item.active {
  background: #f2fbfe;
  border-radius: 8px;
  font-weight: bold !important;
  color: #333e46 !important;
  position: relative;
}
.side-menu__item.active::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  border-radius: 4px 0px 0px 4px;
  background: var(--blue-500, #99d3f2);
  right: -24px;
  top: 0px;
  bottom: 0px;
}
.side-menu__item img:first-child {
  height: 22px;
}
.side-menu__item.active img {
  filter: brightness(0.5);
}

.calendar-block .select2-container--default .select2-selection--single {
  width: 145px;
  height: 44px !important;
  border: 0 !important;
  border-radius: 12px 0 0 12px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.customs-select
  .select2-container--default
  .selection
  .select2-selection--single {
  border-radius: 12px;
  border: 0 !important;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.customs-select .select2-container--default {
  /* width: 100% !important; */
}

.edit-status-notification-errors {
  position: absolute;
  display: flex;
  width: 335px;
  color: #0000;
  background: rgb(237, 84, 84);
  border-radius: 12px;
  padding: 16px 50px 14px 18px;
  left: 12px;
  bottom: 12px;
  gap: 12px;
}
.edit-status-notification-errors .status-block {
  flex: 1;
}
.edit-status-notification-errors h6,
.edit-status-notification-errors p {
  line-height: 1;
  margin-bottom: 0;
  color: white;
}
.edit-status-notification-errors h6 {
  font-weight: bold;
  margin-bottom: 5px;
}

.edit-status-notification-errors .bi-exclamation-circle {
  font-size: 16px;
  color: rgb(255, 255, 255);
  margin-top: 5px;
}
.edit-status-notification-errors button {
  position: absolute;
  line-height: 1;
  background: 0 !important;
  border: 0 !important;
  top: 22px;
  right: 18px;
  color: white;
}
.edit-status-notification-errors button i {
  font-size: 22px;
  color: #000000;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  font-size: 14px;
}
.select-border-field-block .label-block {
  margin-top: 8px !important;
}
.multiple-dropdown-block
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  display: flex;
  align-items: center;
  font-size: 14px !important;
}
.product-select .select2-container--default {
  height: 58px;
  display: flex;
  align-items: center;
}
.product-select .select2-container--default .selection {
  width: 100%;
}
.product-select .select2-container--default .select2-selection--single {
  justify-content: flex-start !important;
}
.product-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 11px !important;
}
.edit-del
  .select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  margin-right: 30px !important;
}

.edit-del
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 14px !important;
}

.mySlides {
  display: none;
}
.mySlides img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.slides_img-edit .prev,
.slides_img-edit .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.slides_img-edit .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.slides_img-edit .prev:hover,
.slides_img-edit .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */

/* Number text (1/3 etc) */

/* The dots/bullets/indicators */
/* .dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
} */

.dot:hover {
  background-color: #717171;
}

/* Fading animation */

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .slides_img-edit .prev,
  .slides_img-edit .next {
    font-size: 11px;
  }
}
.slides_img-edit {
  background-color: #f5f7f9;
}
.edit-profile-wrapper
  .profile-detail-section
  fieldset.select-field
  .form-control {
  background-color: rgb(255, 255, 255) !important;
}
.field-disabled .edit-profile-wrapper .profile-detail-section .form-control {
  background-color: rgb(227, 227, 227) !important;
}
.product-drop
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 0px !important;
}
/* 
body *::-webkit-scrollbar{
    width: auto !important;
    height: auto !important;
    transition: none !important;
} */

.span-file-name {
  position: absolute;
  top: 23px;
  left: 100px;
  background: white;
  padding: 8px;
  color: #545e7a;
  width: 300px;
  padding-left: 11px;
}
.select-timezone-browser,
.select-timezone-browser .select__single-value {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
  color: var(--neutral-900, #475160);
}

.select-timezone-browser .select-filter-items {
  width: 260px !important;
}
.btn-slide-toggle-arrow {
  background-color: #f5f7f9;
  border-radius: 8px;
}
.btn-slide-toggle-arrow {
  cursor: pointer;
  width: 30px;
}
.btn-slide-toggle-arrow:hover {
  opacity: 0.5;
  transition: all 0.2s;
}
.table-tree-slide .slide-row {
  height: 0 !important;
  overflow: hidden;
  border-top: hidden;
}
.table-tree-slide .slide-row.show {
  overflow: hidden;
  border-top: aliceblue;
  transition: all 0.2s;
}
.table-tree-slide .slide-row td {
  padding: 0px !important;
  transition: padding 0.4s;
}
.table-tree-slide .slide-row td .child-row-min {
  max-height: 0;
  padding: 0 24px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}

.table-tree-slide .slide-row.show td .child-row-min {
  max-height: 100%;
  padding: 16px 24px;
  transition: all 0.2s ease-in;
}
