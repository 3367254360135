.container-login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  /* z-index: 999; */
  background-image: url("../../assets/images/image-new/backgroup-black.png") !important;
}
.wrap-header-page .text-header-page {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: #ffffff;
}

.wrap-logo-page {
  margin-bottom: 62px;
}

.wrap-form-submission {
  padding: 63px 80px;
  background: #ffffff;
  /* width: 700px; */
  border-radius: 32px;
}
.inner-logo-page {
  text-align: center;
}
.wrap-header-page {
  margin-bottom: 63px;
}
/* Container for the form section */
.form-group.form-describe-your-operation {
  margin-top: 28px;
}

.text-head-from-group {
  font-weight: bold;
  color: #333;
  display: inline;
}

.red-dot {
  color: red;
  display: inline;
  margin-left: 3px;
}

/* Container for checkboxes */
.wrap-group-input-check-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

/* Each checkbox label container */
.wrap-input-check-box {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s, border-color 0.3s;
}
.wrap-radio-input {
  gap: 16px;
}
.wrap-radio-input label {
  margin: 0 !important;
}
.wrap-button-submit .btn-driver-new {
  padding-top: 18.54px;
  padding-bottom: 18.54px;
  width: 100%;
  background: #333e46;
  border-radius: 9.27227px;
  text-align: center;
  box-shadow: none;
  border: 0;
  font-weight: 600;
  font-size: 20.8626px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #f9fafb;
}
.wrap-discriptiom-form p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #707786;
  margin: 0 !important;
}
.wrap-discriptiom-form {
  margin-top: 24px;
}
