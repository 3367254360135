.page-thankyou {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url("../../assets/images/image-new/bg-gradin.png");
}

.container-login100 {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: none;
}
.login-content {
  position: relative;
  height: 100vh;
  z-index: 99;
}

.login-content .floating-label label {
  color: #535f6b;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.login-content .form-control {
  border-color: #c7cbd3;
}

.login-content .form-control:focus {
  border-color: #4788ff;
}

.login-content .custom-control-label::before {
  background-color: #f7f9ff;
}
.login-content {
  width: 100%;
  height: 100%;
}
.wrap-form-submission {
  padding: 63px 80px;
  background: #ffffff;
  width: 700px;
  border-radius: 32px;
}

.text-head-from-group .text-tag {
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #707786;
}
.form-group .description-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #707786;
}
.form-group .wrap-descrip-text {
  margin-bottom: 16px;
}
.wrap-button-submit .btn-driver-new {
  padding-top: 18.54px;
  padding-bottom: 18.54px;
  width: 100%;
  background: #333e46;
  border-radius: 9.27227px;
  text-align: center;
  box-shadow: none;
  border: 0;
  font-weight: 600;
  font-size: 20.8626px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #f9fafb;
}
.wrap-button-submit {
  margin-top: 67px;
}
.wrap-header-page .text-header-page {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: #ffffff;
}
.wrap-header-page {
  margin-bottom: 63px;
}
.wrap-discriptiom-form p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #707786;
  margin: 0 !important;
}
.wrap-discriptiom-form {
  margin-top: 24px;
}
.wrap-group-input-check-box {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
}
.wrap-text-input-other {
  margin-top: 10px;
}

.wrap-group-input-check-box .wrap-input-check-box {
  padding: 8px;
  border: 1px solid #e2e5e9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 150px;
  min-width: 70px;
  height: 34px;
  position: relative;
}
.wrap-group-input-check-box .wrap-input-check-box label {
  margin: 0;
}
.wrap-radio-input {
  gap: 16px;
}
.wrap-radio-input label {
  margin: 0 !important;
}
.wrap-logo-page {
  margin-bottom: 62px;
}
.inner-logo-page {
  text-align: center;
  z-index: 9999999;
}
.new-driver-page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-table;
  position: relative;
  background-image: url(../../assets//images/image-new/backgroup-black.png);
}

.wrap-input-check-box label {
  margin-left: 25px !important;
}
.wrap-input-check-box-cross input[type="checkbox"]:checked + label::after {
  content: "";
  width: 18px;
  height: 18px;
  background: #e53345;
  border: 1px solid #e53345;
  border-radius: 4px;
  position: absolute;
  left: 8px;
  top: 8px;

  background-repeat: no-repeat;
  background-position: center;
}
.wrap-input-check-box-cross input[type="checkbox"] + label::after {
  content: "";
  width: 18px;
  height: 18px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e2e5e9;
  position: absolute;
  left: 8px;
  top: 8px;
}
.wrap-input-check-box-true input[type="checkbox"]:checked + label::after {
  content: "";
  width: 18px;
  height: 18px;
  background: #5fadec;
  border: 1px solid #5fadec;
  border-radius: 4px;
  position: absolute;
  left: 8px;
  top: 8px;

  background-repeat: no-repeat;
  background-position: center;
}
.wrap-input-check-box-true input[type="checkbox"] + label::after {
  content: "";
  width: 18px;
  height: 18px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e2e5e9;
  position: absolute;
  left: 8px;
  top: 8px;
}
.wrap-input-check-box-true.active {
  background: #f2fbfe !important;
  border: 1px solid #f2fbfe !important;
  border-radius: 8px;
  display: flex !important;
}
.wrap-input-check-box-cross.active {
  background: #fdf0f0 !important;
  border: 1px solid #fdf0f0 !important;
  border-radius: 8px;
  display: flex !important;
}
.text-thx-up {
  font-weight: 600;
  font-size: 64px;
  line-height: 100%;
  color: #ffffff;
  text-align: center;
}
.text-thx-bottom {
  font-weight: 500;
  font-size: 36px;
  line-height: 140%;
  color: #ffffff;
  text-align: center;
}
.btn-go-webpage .btn-back-web {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: 16px 32px;
  cursor: pointer;
}
.btn-go-webpage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.wrap-logo-absolute {
  position: fixed;
  top: 70px;
  right: 47rem;
}
.new-thx-page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-table;
  position: relative;
}
.wrap-footer-cus-thx {
  margin-left: 48px;
}
.red-dot {
  color: #cf0029 !important;
}
.text-drak-simple {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #475160;
}
.text-upper-gray {
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #707786;
}
.text-country-code-new-driver {
  margin-right: 8px;
}
