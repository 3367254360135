.error-img {
  position: relative;
  background-image: url(../../../assets/images/image-new/new_background.png) !important;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-table;
}

.error-img::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
}

.header-brand-img {
  margin-bottom: 15px;
}
.fs-r-2 {
  font-size: 1.75rem !important;
}
.wrap-error100 {
  border-radius: 16px !important;
  box-shadow: 0 0 4px rgb(0 0 0 / 14%), 0 4px 8px rgb(0 0 0 / 6%);
  padding: 5rem 25rem !important;
}
.detail-error {
  font-size: 13px !important;
}

.form-error-set fieldset legend {
  position: absolute;
  width: auto;
  font-size: 12px;
  line-height: 14px;
  color: #475160;
  background: #fff;
  padding-left: 4px;
  padding-right: 4px;
  margin: -8px 0 0 10px;
  z-index: 2;
  font-weight: 400;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  float: left;
}
.form-error-set fieldset {
  border: 1px solid #e2e5e9;
  border-radius: 12px;
  padding: 0;
  margin-bottom: 24px;
  overflow: hidden;
  width: 100%;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

/* input {
  margin: 0.4rem;
} */

.form-control-with-icon-email > input {
  padding: 12px;
  width: 100%;
  color: #475160;
  outline: none;
  padding-left: 45px;
  border: 1px solid #e2e5e9;
  border-radius: 12px;
  background-image: url(../../../assets/images/svgs/email_log.svg);
  background-position: 6px 12px;
  background-repeat: no-repeat;
}

.form-control-with-icon-password > input {
  padding: 12px;
  width: 100%;
  color: #475160;
  outline: none;
  padding-left: 45px;
  border: 1px solid #e2e5e9;
  border-radius: 12px;
  background-image: url(../../../assets/images/svgs/lock.png);
  background-position: 11px 12px;
  background-repeat: no-repeat;
}
.show-password span {
  font-size: 11px;
  padding: 15px;
  cursor: pointer;
  width: 62px;
  font-weight: 400;
}
.credit-error-page {
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 15px;
}

.credit-error-page p {
  color: #fff;
  font-size: 13px;
  margin: 0;
}

.txt-forgot-password {
  font-size: 14;
  text-decoration: "underline";
  font-weight: 400;
}

.wrap-error100 {
  background: #fff;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 137px 130px 137px 95px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  z-index: 1;
}

.container-error100 {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  z-index: 999;
}

.error100-form-btn {
  line-height: 1.5;
  color: #fff;
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  border-radius: 5px;
}
