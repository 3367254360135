.main-title-page {
  font-size: 30px;
  font-weight: bold;
  color: #475160;
}
h1 {
  font-size: 30px;
  font-weight: bold;
  color: #475160;
}
