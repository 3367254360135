.page-frist .page-header {
  display: none;
}

.cv-chart-block {
  display: block;
  box-sizing: border-box;
  height: 235px;
  width: 521px;
}

.btn-sf i {
  position: relative;
  font-size: 10px;
  color: #475160;
  margin-right: 5px;
  top: -2px;
}

.accordion-button {
  position: relative;
  padding: 0 !important;
  color: #475160 !important;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* font-size: 1rem; */
  /* background-color: var(--bs-accordion-btn-bg); */
  border: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.accordion-button:not(.collapsed) .show-filters-btn {
  display: none;
}

.accordion-button:not(.collapsed) {
  background: #475160;
}

.accordion-button:not(.collapsed) .hide-filters-btn {
  display: block !important;
  font-size: 16px;
  color: #ffffff;
  line-height: 44px;
}

.accordion-button:not(.collapsed) i {
  color: #ffffff;
}

.accordion-button::after {
  background-image: none;
  content: none;
  font-family: feather !important;
}

.accordion-item {
  background-color: #f5f7f9 !important;
  border: 1px solid #f5f7f9 !important;
}

.accordion-collapse.show {
  margin-top: 1rem;
  position: inherit;
  margin-bottom: 13rem;
}

.accordion-collapse.show .accordion-body {
  background: #f1f3f6;
  border-radius: 20px;
  padding: 15px;
  width: 91rem;
  height: auto;
  position: absolute;
  top: 4rem;
}

.accordion-collapse .accordion-body {
  background: #f1f3f6;
  border-radius: 20px;
  padding: 15px;
  width: 91rem;
  height: auto;
  position: absolute;
  top: 4rem;
}

/* .Select__control .Select__indicators {
  display: none;
} */

.Select__control {
  width: 120px;
  border-radius: 12px 0 0 12px !important;
  border: 0px solid !important;
  height: 100%;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border: 0px !important;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.MuiFormControl-root .MuiOutlinedInput-root {
  padding-right: 0 !important;
  height: 38px;
  width: 122px;
}

.MuiFormControl-root .MuiFormLabel-root {
  display: none;
}

.MuiInputBase-root input {
  font-size: 14px;
}

.wrapper_form_filter_date_time_dsh {
  width: 270px;
  border-radius: 0 12px 12px 0 !important;
}

.wrapper_form_filter_date_time {
  width: 270px;
  border-radius: 0 12px 12px 0 !important;
}

.cuttomdate .css-13cymwt-control {
  height: 46px;
}

.Select__single-value {
  padding-left: 10px;
}

.wrapper_dsh_filter_search {
  width: auto;
  border: 1px solid #e2e5e9;
  border-radius: 12px;
  padding: 4px 4px 4px 10px;
}

.select-dsh-filter-items {
  width: 80%;
  font-size: 14px;
  border: 0px solid;
  border-radius: 8px;
  height: 34px;
  color: #475160;
}

.chart-line-dsh,
.chart-line-dsh .chart-dropshadow,
.chart-line-dsh .chart-dropshadow .apexcharts-canvas,
.chart-line-dsh .chart-dropshadow .apexcharts-canvas svg {
  height: inherit !important;
  min-height: auto !important;
}

.css-b62m3t-container {
  height: 100%;
}

.custom-filter-dash .css-1jqq78o-placeholder {
  display: flex;
  overflow: hidden;
  min-inline-size: max-content;
}

.wrapper-data-table-ds {
  position: relative !important;
}

.row_wrapper_filter {
  margin-left: 1px;
  margin-top: 10px;
}
.row_wrapper_filter fieldset {
  border: unset;
}

.select-filter-items-dashboard-page .select__control {
  border: 0;
  border-radius: 8px;
  width: 100%;
}
.select-filter-items-dashboard-page .select__control .select__indicator-separator {
  width: 0;
}

.Select__control {
  width: 100%;
}

.select-filter-items-dashboard-page {
  width: 100%;
  font-size: 14px;
  border: 0px solid;
  border-radius: 8px;
  height: 100%;
  color: #475160;
}

.custom-inner .css-13cymwt-control {
  font-size: 14px;
}

.date-input {
  display: contents;
}

.select-filter-items-date {
  width: 40% !important;
}
.wrapper_form_filter_date_time {
  width: 59% !important;
}
.wrapper_form_filter_date_time .MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
}
.wrapper_form_filter_date_time
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100% !important;
}
.css-t3ipsp-control {
  box-shadow: unset !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 1px;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}

.css-1nmdiq5-menu {
  top: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}

.dashboard-datepicker-block {
  background: #fff;
  border-left: 1px solid #f5f7f9;
  border-radius: 0 12px 12px 0;
  padding-left: 10px;
  overflow: hidden;
}
.dashboard-datepicker-block .input-datepicker {
  width: 90px;
  border: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.dashboard-datepicker-block .bi-calendar-minus-fill {
  font-size: 16px;
  line-height: 42px;
  margin-right: 10px;
}
.dashboard-datepicker-block .bi-arrow-right-short {
  font-size: 24px;
  line-height: 42px;
}
.select-border-field-block {
  border: 1px solid #e2e5e9;
  border-radius: 12px;
  padding: 4px 4px 4px 10px;
  gap: 10px;
}
.select-border-field-block .label-block {
  margin-top: 5px;
}
.select-border-field-block .form-select {
  width: 135px;
  border: 0;
  border-radius: 8px;
  height: 34px;
  line-height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.dashboard-section-wrapper {
  margin-top: 20px;
  gap: 20px;
}
.dashboard-section .row {
  margin-top: 20px;
}
.dashboard-section .row:first-child {
  margin-top: 0;
}
.dashboard-card {
  height: 100%;
  border: 1px solid #f1f3f6;
  border-radius: 20px;
  background: #fff;
  padding: 24px;
}
.dashboard-card h3 {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 10px;
  padding-left: 10px;
}
.dashboard-card .deliver-label {
  font-size: 18px;
  line-height: 1;
  border: 1px solid #f1f3f6;
  border-radius: 8px;
  padding: 10px 14px;
  margin-top: -8px;
}
.dashboard-card .title-note {
  color: #afbbca;
}
.dashboard-card .chart-block {
  height: 235px;
  margin-top: 10px;
}
.dashboaord-info-card-section .info-card {
  /* width: 280px; */
  /* height: 150px; */
  height: 200px;
  background: #fff;
  border-radius: 20px;
  padding: 24px;
  margin-bottom: 20px;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
  position: relative;
}
.dashboaord-info-card-section .info-card:hover {
  color: #fff;
}
.dashboaord-info-card-section .info-card:hover .icon-block,
.dashboaord-info-card-section .info-card:hover .title-text {
  color: #fff;
}
.dashboaord-info-card-section .info-card:hover .icon-block i,
.dashboaord-info-card-section .info-card:hover .title-text i {
  color: #fff !important;
}
.dashboaord-info-card-section .info-card:hover .num-right-block p:nth-child(2) {
  color: #fff;
}
.dashboaord-info-card-section .info-card .title-text {
  color: #475160;
}
.dashboaord-info-card-section .info-card .icon-block {
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 1;
  border-radius: 12px;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.dashboaord-info-card-section .info-card .icon-block .bi-clipboard-minus {
  font-size: 18px;
  line-height: 48px;
}
.dashboaord-info-card-section .info-card .icon-block .fa-truck {
  font-size: 20px;
  line-height: 48px;
}
.dashboaord-info-card-section .info-card .num-block-wrapper {
  margin-top: 20px;
  gap: 10px;
}
.dashboaord-info-card-section .info-card .num-block {
  font-size: 28px;
  line-height: 1;
}
.dashboaord-info-card-section .info-card .num-right-block {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.dashboaord-info-card-section .info-card .num-right-block p {
  line-height: 1;
  margin: 0;
}
.dashboaord-info-card-section .info-card .num-right-block p:nth-child(2),
.since-last {
  color: #707784;
  font-size: 12px;
}
.dashboaord-info-card-section
  .info-card
  .num-right-block
  .bi-arrow-right-short {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* gmv-card */
.dashboaord-info-card-section .info-card.gmv-card:hover .money-up-green,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-up-green p,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-default,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-default p,
.dashboaord-info-card-section
  .info-card.gmv-card:hover
  .money-up-green
  p.since-last,
.dashboaord-info-card-section
  .info-card.gmv-card:hover
  .money-up-green
  i
  .dashboaord-info-card-section
  .info-card.gmv-card:hover
  .money-down-red,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-down-red p,
.dashboaord-info-card-section .info-card.gmv-card:hover p.since-last,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-down-red p i {
  color: #fff !important;
}

.dashboaord-info-card-section .info-card.gmv-card:hover {
  background: #99d3f2;
}
.dashboaord-info-card-section .info-card.gmv-card:hover .icon-block {
  background: #bde4f5;
}
.dashboaord-info-card-section .info-card.gmv-card .icon-block {
  background: #e3f5fb;
}
.dashboaord-info-card-section .info-card.gmv-card .icon-block i {
  color: #99d3f2;
}

/* credits-card */
.dashboaord-info-card-section .info-card.credits-card:hover .money-up-green,
.dashboaord-info-card-section .info-card.credits-card:hover .money-up-green p,
.dashboaord-info-card-section .info-card.credits-card:hover .money-default,
.dashboaord-info-card-section .info-card.credits-card:hover .money-default p,
.dashboaord-info-card-section
  .info-card.credits-card:hover
  .money-up-green
  p.since-last,
.dashboaord-info-card-section
  .info-card.credits-card:hover
  .money-up-green
  i
  .dashboaord-info-card-section
  .info-card.credits-card:hover
  .money-down-red,
.dashboaord-info-card-section .info-card.credits-card:hover .money-down-red p,
.dashboaord-info-card-section .info-card.credits-card:hover p.since-last,
.dashboaord-info-card-section
  .info-card.credits-card:hover
  .money-down-red
  p
  i {
  color: #fff !important;
}

.dashboaord-info-card-section .info-card.credits-card:hover {
  background: #ecb888;
}
.dashboaord-info-card-section .info-card.credits-card:hover .icon-block {
  background: #f5d49e;
}
.dashboaord-info-card-section .info-card.credits-card:hover .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.credits-card:hover p:first-child {
  color: #fff;
}
.dashboaord-info-card-section .info-card.credits-card .icon-block {
  background: #fbf0cc;
}
.dashboaord-info-card-section .info-card.credits-card .icon-block i {
  color: #ecb888;
}
.dashboaord-info-card-section .info-card.credits-card .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.credits-card p:first-child {
  color: #f5d49e;
}

/* visits-card */
.dashboaord-info-card-section .info-card.visits-card:hover .money-up-green,
.dashboaord-info-card-section .info-card.visits-card:hover .money-up-green p,
.dashboaord-info-card-section .info-card.visits-card:hover .money-default,
.dashboaord-info-card-section .info-card.visits-card:hover .money-default p,
.dashboaord-info-card-section
  .info-card.visits-card:hover
  .money-up-green
  p.since-last,
.dashboaord-info-card-section
  .info-card.visits-card:hover
  .money-up-green
  i
  .dashboaord-info-card-section
  .info-card.visits-card:hover
  .money-down-red,
.dashboaord-info-card-section .info-card.visits-card:hover .money-down-red p,
.dashboaord-info-card-section .info-card.visits-card:hover p.since-last,
.dashboaord-info-card-section .info-card.visits-card:hover .money-down-red p i {
  color: #fff !important;
}

.dashboaord-info-card-section .info-card.visits-card:hover {
  background: #475160;
}
.dashboaord-info-card-section .info-card.visits-card:hover .icon-block {
  background: #92a3b0;
}
.dashboaord-info-card-section .info-card.visits-card .icon-block {
  background: #e2e5e9;
}
.dashboaord-info-card-section .info-card.visits-card .icon-block i {
  color: #92a3b0;
}

/* deliveries-card */

.dashboaord-info-card-section .info-card.deliveries-card:hover .money-up-green,
.dashboaord-info-card-section
  .info-card.deliveries-card:hover
  .money-up-green
  p,
.dashboaord-info-card-section .info-card.deliveries-card:hover .money-default,
.dashboaord-info-card-section .info-card.deliveries-card:hover .money-default p,
.dashboaord-info-card-section
  .info-card.deliveries-card:hover
  .money-up-green
  p.since-last,
.dashboaord-info-card-section
  .info-card.deliveries-card:hover
  .money-up-green
  i
  .dashboaord-info-card-section
  .info-card.deliveries-card:hover
  .money-down-red,
.dashboaord-info-card-section
  .info-card.deliveries-card:hover
  .money-down-red
  p,
.dashboaord-info-card-section .info-card.deliveries-card:hover p.since-last,
.dashboaord-info-card-section
  .info-card.deliveries-card:hover
  .money-down-red
  p
  i {
  color: #fff !important;
}

.dashboaord-info-card-section .info-card.deliveries-card:hover {
  background: #add5b7;
}
.dashboaord-info-card-section .info-card.deliveries-card:hover .icon-block {
  background: #bee7c6;
}
.dashboaord-info-card-section
  .info-card.deliveries-card:hover
  .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.deliveries-card:hover p:first-child {
  color: #fff;
}
.dashboaord-info-card-section .info-card.deliveries-card .icon-block {
  background: #daf3d7;
}
.dashboaord-info-card-section .info-card.deliveries-card .icon-block i {
  color: #add5b7;
}
.dashboaord-info-card-section .info-card.deliveries-card .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.deliveries-card p:first-child {
  color: #add5b7;
}

.money-default,
.money-default p {
  color: #475160 !important;
}

.money-up-green,
.money-up-green p {
  color: #70c2a8 !important;
}
.money-down-red,
.money-down-red p {
  color: #e43346 !important;
}

.select-single-value .select__single-value {
  padding-left: 10px;
}
.select-single-value .select__placeholder {
  padding-left: 10px;
}

.sub-title-page-laptop {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.wrap-filter-field-set {
  margin-top: 10px;
  position: relative;
  padding: 6px;
  border: 1px solid #ced5dd;
  border-radius: 12px;
  background: #f5f7f9;
}
.field-set-header {
  position: absolute;
  top: -8px;
  left: 14px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #475160;
}
.dashboard-wrap-input-date {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
}
.dashboard-wrap-input-date
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root:last-child {
  background: #ffffff00 !important;
}
.dashboard-wrap-input-date input.first {
  border: 0px;
  border-right: 1px solid #ecf0f2;
  border-radius: 8px 0px 0px 8px;
  margin: 0;
}
.dashboard-wrap-input-date .custom-inner .css-13cymwt-control {
  background: #ffffff00;
}
.dashboard-wrap-input-date input.second {
  border: 0px !important;
  border-radius: 0px;
  margin: 0;
}
.dashboard-wrap-input-date input.third {
  border: 0px !important;
  border-radius: 0px 8px 8px 0px;
  margin: 0;
}
.dashboard-wrap-last-ref {
  text-align: end;
}
.dashboard-wrap-last-ref .dashboard-head-last-ref {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #92a3b0;
}
.dashboard-wrap-last-ref .dashboard-span-time-ago {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #475160;
}
.wrap-box-card {
  background: #ffffff;
  border: 1px solid #f1f3f6;
  border-radius: 24px;
  /* padding: 20px 16px 20px 24px; */
}
.span-let-see-going {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #707786;
}
.dashboard-head-card {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #333e46;
}
.txt-left-box-average-gmv,
.wrap-route-rcy-right .mile {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #92a3b0;
}
.txt-left-box-average-gmv {
  min-width: 55px;
}
.dashboard-wrap-percen {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 4px 2px 5px;
  border-radius: 4px;
}
.dashboard-wrap-percen.danger {
  color: #d91125;
  background: #fdf0f0;
}
.dashboard-wrap-percen.success {
  color: #4ea195;
  background: #f0faf3;
}
.dashboard-wrap-percen.zero {
  color: #68788d;
  background: #f1f3f6;
}
.txt-right-box-average-gmv {
  margin-right: 5px;
}
.wrap-route-efficiency {
  background: #f9fafb;
  border-radius: 8px;
  padding: 12px;
  gap: 40px;
  margin-top: 15px;
}
.txt-route-rcy {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #475160;
}
.txt-dota-since {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #475160;
}
.txt-val-net-gmv {
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 42px;
  text-transform: capitalize;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #475160;
}
.dashboard-wrap-total-gmv .val-total-gmv,
.dashboard-wrap-credits-gmv .val-credits,
.dashboard-wrap-buyback .val-buyback {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #475160;
}
.dashboard-wrap-credits-gmv,
.dashboard-wrap-buyback {
  display: flex;
  gap: 10px;
  align-items: center;
}
.canvas-dsb-doughnut {
  height: 150px !important;
  width: 150px !important;
}
.canvas-dsb-doughnut-top-brand {
  height: 200px !important;
  width: 200px !important;
  margin: auto;
}
.wrap-half-progress {
  position: relative;
  margin-top: -20px;
}
.wrap-box-percent-completed-store-visits {
  position: absolute;
  top: 75px;
  right: 25%;
  left: 25%;
}
.wrap-box-percent-completed-store-visits .txt-percent-completed-store-visits {
  font-weight: 500;
  font-size: 42px;
  line-height: 42px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #475160;
  bottom: 65px !important;
  width: auto;
}
.wrap-txt-percent-success .txt-left-percent-success {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #475160;
}
.wrap-box-percent-completed-store-visits
  .txt-percent-completed-store-visits
  .percent-store-visits {
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #475160;
  margin-left: 3px;
}
.wrap-txt-right-percent-success,
.wrap-txt-right-percent-success .txt-right-percent-success {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #92a3b0;
}
.dashboard-wrap-best-driver,
.dashboard-wrap-best-store {
  width: 100%;
  border: 1px solid #f1f3f6;
  border-radius: 16px 16px 0 0;
  margin-top: 10px;
  padding: 1rem;
}
.dashboard-wrap-best-driver {
  margin-top: 20px;
}
.wrap-img-driver,
.wrap-img-store {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
.wrap-img-store img {
  max-width: 100%;
}
.wrap-img-driver img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 50%;
}
.text-head-best-gmv {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #475160;
}
.menu-driver-bar-bottom,
.menu-store-bar-bottom {
  width: 100%;
  border: 1px solid #f1f3f6;
  border-radius: 0px 0px 16px 16px;
  padding: 0.5rem;
  background: #f9fafb !important;
  border-top: 0;
}
.wrap-btn-menu-driver .btn-menu-driver,
.wrap-btn-menu-store .btn-menu-store {
  padding: 5px 15px;
  background: #ffffff;
  border: 1px solid #afbbca;
  border-radius: 8px;
}

.wrap-btn-menu-driver .btn-menu-driver:hover,
.wrap-btn-menu-store .btn-menu-store:hover {
  opacity: 0.4;
  transition: all 0.2s;
}

.lnk-sale-rep .txt-sale-lnk {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #475160;
}
.lnk-sale-rep .txt-sale-lnk:hover {
  /* color: #192332; */
  opacity: 0.4;
  transition: all 0.2s;
}
.card-title-dashboard .txt-top-brand {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #92a3b0;
}
.inner-val-top-gmv {
  position: absolute;
  top: 39%;
  right: 24%;
  left: 24%;
  text-align: center;
}
.col-wrap-dsb-chart-top-gmv {
  position: relative;
}
.wrap-txt-total .txt-total {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #92a3b0;
}
.wrap-txt-val-total .txt-val-total {
  font-size: 16px;
  line-height: normal;
  color: #535f6b;
  font-weight: 500;
}
.val-top-gmv {
  display: flex;
  align-items: center;
}
.val-top-gmv .txt-gmv-best {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #68778d;
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.val-buybacks .txt-gmv-best {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #68778d;
  width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.val-top-gmv .txt-per-gmv-best,
.val-buybacks .txt-per-gmv-best {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #475160;
  margin-left: 8px;
}
.val-top-gmv .circle-gmv-best,
.val-top-gmv .circle-credit-best {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
}

/* circle gmv */
.val-top-gmv .circle-gmv-best.first {
  background: #357d7f;
}
.val-top-gmv .circle-gmv-best.second {
  background: #4ea195;
}
.val-top-gmv .circle-gmv-best.third {
  background: #6bc0a5;
}
.val-top-gmv .circle-gmv-best.fourth {
  background: #aee6c6;
}
.val-top-gmv .circle-gmv-best.fifth {
  background: #d0f0dc;
  border: 1px solid #9de0bd;
}
.val-top-gmv .circle-gmv-best.sixth {
  background: #f0faf3;
  border: 1px solid #9de0bd;
}

/* circle credits */
.val-top-gmv .circle-credit-best.first {
  background: #f05200;
}
.val-top-gmv .circle-credit-best.second {
  background: #fc8934;
}
.val-top-gmv .circle-credit-best.third {
  background: #ffb867;
}
.val-top-gmv .circle-credit-best.fourth {
  background: #ffde9b;
}
.val-top-gmv .circle-credit-best.fifth {
  background: #fff6ce;
  border: 1px solid #ffcc81;
}
.val-top-gmv .circle-credit-best.sixth {
  background: #fefae8;
  border: 1px solid #ffcc81;
}
.wrap-box-card-left {
  background: #ffffff;
  border: 1px solid #f1f3f6;
  border-radius: 24px 0px 0px 20px;
  border-right: 0;
}
.wrap-box-card-right {
  background: #ffffff;
  border: 1px solid #f1f3f6;
  border-radius: 0px 24px 24px 0px;
  border-left: 0;
}
.wrap-box-net-best {
  padding-right: 0 !important;
}
.wrap-box-credit-best {
  padding-left: 0 !important;
}
.dsb-wrap-group-data-net-gmv {
  margin-top: 50px;
}
.dsb-wrap-box-bottom-right,
.dsb-wrap-box-bottom-left {
  margin: 0;
  height: 420px;
}
.dsb-wrap-credit-top-left {
  margin-top: 20px;
}
.dsb-form-net-gmv-top-left {
  margin: 0;
}
.dsb-form-net-gmv-top-left .dashboard-warp-card-net-gmv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.dsb-form-total-gmv-top-left {
  margin-top: 65px;
}
.dsb-wrap-box-avg-top-center,
.dsb-wrap-box-completed-top-right,
.dsb-wrap-box-net-top-left {
  height: 250px;
}
.wrap-route-efficiency .wrap-mobile-eff {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
/* .wrap-route-rcy-right {
  margin-left: auto;
} */
.dsb-wrap-box-bottom-right {
  margin-top: 0px;
}
.panel-custom-header-dsh {
  border: 0;
}
.panel-custom-header-dsh .nav-item .nav-link.active {
  background-color: #ffffff !important;
  border: 1px solid #ffffff;
  color: #333e46 !important;
  padding: 4px 14px;
  border-radius: 8px;
  margin: 0px;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  margin-right: 5px;
}
.panel-custom-header-dsh .nav-item .nav-link {
  padding: 4px 14px !important;
  gap: 8px;
  border: 1px solid #ced5dd;
  border-radius: 8px;
  margin: 0px;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #333e46 !important;
  margin-right: 5px;
}
.tab-dsb {
  padding-bottom: 25px;
}
.tab-dsb .nav-item a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
.dashboard-wrap-input-date .select-filter-items-date {
  width: 100% !important;
  background: #ffffff00;
  border: 0 !important;
  border-radius: 0;
}
.dashboard-wrap-input-date .Select--is-disabled .Select__control--is-disabled {
  background-color: hsl(0deg 0% 100%) !important;
}
/* .dashboard-wrap-input-date .css-b62m3t-container {
  width: 100% !important;
  background: #ffffff00;
  border: 0 !important;
  border-radius: 0;
} */
.wrap-dashboard-sub-title-right .wrap-filter-field-set {
  width: 30rem;
}
.wrap-txt-name-driver-dsh .txt-name-driver-dsh,
.wrap-txt-name-store-dsh .txt-name-store-dsh {
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #475160;
}
.wrap-location-bottom-dsh {
  min-width: 80%;
}
.wrap-location-bottom-dsh .location-bottom-dsh {
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: -0.02em;
  color: #68778d;
  /* margin-left: 5px; */
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

@media only screen and (max-width: 1437px) {
  .txt-route-rcy {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #475160;
  }
  .txt-left-box-average-gmv,
  .wrap-route-rcy-right .mile,
  .txt-left-box-average-gmv,
  .wrap-route-rcy-right .data,
  .dashboard-wrap-percen {
    font-size: 11px;
  }
  .dashboard-wrap-total-gmv .val-total-gmv,
  .dashboard-wrap-credits-gmv .val-credits,
  .dashboard-wrap-buyback .val-buyback {
    font-size: 14px;
  }
  .wrap-text-since .txt-since-time {
    font-size: 12px;
  }
  .txt-val-net-gmv,
  .wrap-box-percent-completed-store-visits .txt-percent-completed-store-visits {
    font-size: 40px;
  }
  .txt-dota-since,
  .wrap-box-percent-completed-store-visits
    .txt-percent-completed-store-visits
    .percent-store-visits {
    font-size: 20px;
  }
  .dsb-wrap-box-bottom-right {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 1343px) {
  .txt-route-rcy {
    font-size: 11px;
  }
  .txt-left-box-average-gmv,
  .wrap-route-rcy-right .mile,
  .txt-left-box-average-gmv,
  .wrap-route-rcy-right .data,
  .dashboard-wrap-percen {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1294px) {
  .txt-route-rcy {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1280px) {
  .dashboard-wrap-total-gmv .val-total-gmv,
  .dashboard-wrap-credits-gmv .val-credits,
  .dashboard-wrap-buyback .val-buyback {
    font-size: 12px;
  }
  .dashboard-head-card {
    font-size: 16px;
  }
  .txt-right-box-average-gmv {
    font-size: 12px;
  }
  .txt-left-box-average-gmv {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .txt-route-rcy {
    font-size: 9px;
  }
}
@media only screen and (max-width: 1573px) {
  .dsb-wrap-credit-top-left {
    margin-top: 0px;
  }
  .dashboard-wrap-credits-gmv,
  .dashboard-wrap-buyback {
    flex-wrap: wrap;
    gap: 0px;
  }
}
@media only screen and (max-width: 820px) {
  .dsb-wrap-box-avg-top-center,
  .dsb-wrap-box-completed-top-right,
  .dsb-wrap-box-net-top-left {
    height: 260px;
  }
  .txt-val-net-gmv,
  .wrap-box-percent-completed-store-visits .txt-percent-completed-store-visits {
    font-size: 36px;
  }
  .txt-dota-since,
  .wrap-box-percent-completed-store-visits
    .txt-percent-completed-store-visits
    .percent-store-visits {
    font-size: 16px;
  }
  .dashboard-wrap-total-gmv .val-total-gmv,
  .dashboard-wrap-credits-gmv .val-credits,
  .dashboard-wrap-buyback .val-buyback {
    font-size: 12px;
  }
  .txt-left-box-average-gmv,
  .wrap-route-rcy-right .mile,
  .txt-left-box-average-gmv,
  .wrap-route-rcy-right .data,
  .dashboard-wrap-percen {
    font-size: 9px;
  }
  .txt-right-box-average-gmv {
    font-size: 10px;
  }
  .txt-left-box-average-gmv {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .wrap-route-efficiency {
    margin-top: 8px;
  }
  .wrap-route-efficiency .wrap-mobile-eff {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  /* .wrap-route-rcy-right {
    margin-left: 0;
  } */
  .dsb-form-net-gmv-top-left,
  .dsb-form-net-gmv-top-left-mobile {
    width: 50%;
  }
  .dsb-col-mobile-res {
    width: 65%;
  }
  .wrap-box-net-best,
  .wrap-box-credit-best {
    width: 50%;
  }
  .dsb-wrap-box-bottom-right {
    margin-top: 30px;
  }
  .dsh-info-box-bestper {
    display: flex;
    width: 100%;
    gap: 15px;
  }
  .dsh-info-box-bestper .driver-box {
    width: 50%;
  }
  .dsh-info-box-bestper .stroe-box {
    width: 50%;
  }
  .dashboard-wrap-best-driver {
    margin-top: 10px;
  }
  .dsb-wrap-box-bottom-right {
    height: 230px;
  }
  .txt-left-box-average-gmv {
    font-size: 10px;
  }
}
@media only screen and (max-width: 414px) {
  .wrap-route-efficiency .wrap-mobile-eff {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .page-first .page-header {
    margin: 0rem 0rem 0.5rem;
  }
  .sub-title-page-mobile {
    flex-direction: column;
    width: 100%;
  }
  .dashboard-wrap-last-ref {
    text-align: start;
  }
  .wrap-dashboard-sub-title-right {
    width: 100%;
  }
  .dashboard-wrap-input-date {
    flex-direction: column;
  }
  .dashboard-wrap-input-date input {
    margin-top: 5px !important;
  }
  .dashboard-wrap-input-date input.first,
  .dashboard-wrap-input-date input.second,
  .dashboard-wrap-input-date input.third {
    border-radius: 8px 8px 8px 8px;
  }
  .wrap-half-progress {
    margin-top: -40px;
  }
  .chart-container {
    height: 200px;
    width: 200px;
    margin: auto;
  }
  .wrap-box-percent-completed-store-visits {
    top: 90px;
  }
  .wrap-box-net-best,
  .wrap-box-credit-best {
    width: 100%;
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .wrap-box-card-right,
  .wrap-box-card-left {
    border-radius: 24px;
  }
  .dsb-wrap-box-bottom-left {
    height: 440px;
  }
  .dsb-wrap-box-bottom-right {
    height: 480px;
  }
  .dsh-info-box-bestper {
    flex-direction: column;
  }
  .dsh-info-box-bestper .driver-box,
  .dsh-info-box-bestper .stroe-box {
    width: 100%;
  }
  .dsb-wrap-group-data-net-gmv {
    margin-top: 20px;
  }
  .val-top-gmv .txt-gmv-best {
    width: 200px;
  }
  .val-top-gmv .circle-gmv-best,
  .val-top-gmv .circle-credit-best {
    margin-right: 30px;
  }
  .wrap-box-credit-best {
    margin-top: 20px;
  }
  .txt-left-box-average-gmv {
    font-size: 12px;
  }
  .txt-route-rcy {
    font-size: 12px;
  }
  .txt-val-net-gmv,
  .wrap-box-percent-completed-store-visits .txt-percent-completed-store-visits {
    font-size: 42px;
  }
  .txt-dota-since,
  .wrap-box-percent-completed-store-visits
    .txt-percent-completed-store-visits
    .percent-store-visits {
    font-size: 22px;
  }
  .dashboard-wrap-total-gmv .val-total-gmv,
  .dashboard-wrap-credits-gmv .val-credits,
  .dashboard-wrap-buyback .val-buyback {
    font-size: 14px;
  }
  .txt-left-box-average-gmv,
  .wrap-route-rcy-right .mile,
  .txt-left-box-average-gmv,
  .wrap-route-rcy-right .data,
  .dashboard-wrap-percen {
    font-size: 12px;
  }
  .txt-right-box-average-gmv {
    font-size: 14px;
  }
  .val-top-gmv .txt-gmv-best {
    font-size: 14px;
  }
}

.ico-question {
  padding-bottom: 5px !important;
}
.ico-prefix-net-gmv {
  padding-bottom: 5px;
  margin-right: 10px;
}
.title-best-performance {
  margin-left: 5px;
}

.chart-dashboard-left::after {
  position: absolute !important;
  content: "";
  height: 360px;
  width: 1px;
  top: 30px;
  position: absolute;
  left: 100%;
  background: #f1f3f6;
  z-index: 9;
}
